import { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { UseThemeStatus } from "../../../../functions/themeMode";
import AvailableOnFindCardName from "../../../profile/profilecollection/nftdetailpage/availableonfindcardname";
import { ListForAuction } from "./listforauction/listforauction";
import { ListForSale } from "./listforsale/listforsale";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "../../../../functions/script";
import "./listname.css";
import FUSDSticker from "../../ftstickers/FUSDSticker";
import { useWalletType } from "../../../../functions/useWalletType";
import { useUserProfile } from "../../../../functions/useUserProfile";

export function ListName({ lease, user }) {
  const [salesType, setSalesType] = useState("sale");
  const [forSale, setIsForSale] = useState(false);
  const [forAuction, setIsForAuction] = useState(false);
  const [listingDetails, setListingDetails] = useState({});
  const walletType= useWalletType()
  const userProfile = useUserProfile()


  useEffect(() => {
    if (lease.salePrice > 0) {
      setIsForSale(true);
      setSalesType("auction");
    }
    if (lease.auctionStartPrice > 0) {
      setIsForAuction(true);
    }
    if (lease.salePrice > 0 && lease.auctionStartPrice > 0) {
      setSalesType("none");
    }

    if (forSale || forAuction) {
      async function getNameListingDetails(name) {
        const listingDetailsResponse = await Script(scripts.getNameDetails, {
          user: name,
        });
        setListingDetails(listingDetailsResponse);
      }
      try {
        getNameListingDetails(lease.name);
      } catch (error) {
        //console.log(error);
      }
    }
  }, [lease]);

  const toggleSaleOption = (option) => {
    if (option === "sale") {
      return (
        <div>
          <ListForSale lease={lease.name} walletType={walletType} user={userProfile} validUntil={lease.validUntil}  />
        </div>
      );
    } else if (option === "auction") {
      return (
        <div>
          <ListForAuction lease={lease.name} walletType={walletType} user={userProfile} validUntil={lease.validUntil} />
        </div>
      );
    }
  };

  return (
    <div id="list-name" data-theme={UseThemeStatus()} className="form-inputs">

{/*      {!forAuction && (
        <Row>
          <Col>
            {!forSale ? (
              <Form.Check
                type="radio"
                id="auction"
                label="List for auction"
                name="salestype"
                onChange={() => setSalesType("auction")}
              />
            ) : (
              <Form.Check
                type="radio"
                id="auction"
                label="List for auction"
                name="salestype"
                onChange={() => setSalesType("auction")}
                defaultChecked
              />
            )}
          </Col>
        </Row>
      )}*/}
      <Row className="my-4">
        <Col>{toggleSaleOption(salesType)}</Col>
      </Row>


      {(forSale || forAuction) &&
        <>
          <Col className="pt-3 pb-2 fw-bold mx-0">
            Current listings:
          </Col>
          <Row className="w-100 mx-0">
            <AvailableOnFindCardName lease={lease} isName={true} forSale={forSale} forAuction={forAuction}/>
          </Row>
      </>
      }

    </div>
  );
}
