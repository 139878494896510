// import { useParams } from "react-router-dom";
import { useRange } from "react-instantsearch-hooks-web";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { UseThemeStatus } from "../../../functions/themeMode";

const EstimatedCollectionValue = ({ numOfNFTs }) => {
  const {  range } = useRange({
    attribute: "amount",
  });
  // const { colName } = useParams();

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "12px" }}>Based on floor price</span>
    </Tooltip>
  );

  return (
    <div>
      <h6>
        Estimated Collection Value: {range.min * parseInt(numOfNFTs)} FLOW
        <OverlayTrigger placement="top" overlay={renderTooltip}>
          <span style={{ marginLeft: "0.5rem" }}>
            {UseThemeStatus() === "light" ? (
              <Image
                fluid={+true}
                src="/assets/img/profile/pubicons/info-light.svg"
                alt=""
                height="15px"
                width="15px"
              />
            ) : (
              <Image
                fluid={+true}
                src="/assets/img/profile/pubicons/info-dark.svg"
                alt=""
                height="15px"
                width="15px"
              />
            )}
          </span>
        </OverlayTrigger>
      </h6>
    </div>
  );
};

export default EstimatedCollectionValue;
