import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import "./pfpselectcollectiontabpage.css";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "../../functions/script";
import { CollectionNftPfpSelect } from "./collectionnftpfpselect";

export function PfpSelectCollectionTabPage({
  collectionData,
  name,
  address,
  setPfpLink,
  colName,
}) {
  const [collectionItems, setCollectionItems] = useState();
  const [collectionExtraItems, setCollectionExtraItems] = useState();
  const [arrayNextPosition, setArrayNextPosition] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const loadLength = 40;
  const [collection, setCollection] = useState([]);
  const [loadMoreIDs, setLoadMoreIDs] = useState();
  let extraIDsArr = [];
  const [lastClicked, setLastClicked] = useState("none");

  useEffect(() => {
    if (collectionData) {
      extraIDsArr = collectionData.extraIDs.map(Number);
      if (collectionData.extraIDs.length > 0) {
        if (collectionData.extraIDs.length <= loadLength) {
          /*
					Similar to collection tab page
					(I am not sure if this page is in use, if it is, it is broken. Because "getAdditionalFactoryCollectionItems" is no longer in use)
					Ben: extraIDsArr will become collectionData.ids.map(Number)

					To get the current collection data, we can run the script that is defined in collectionData.resolver

					The getExtraItems function should look sth like this

					let extraIDsArray = {
						[collectionData.key]: extraIDsArr,
					}
					async function getExtraItems(addr, extraIDsArray) {
								const additionalFactoryItemsResponse = await Script(
									collectionData.resolver,
									{ address: addr, collectionIDs: extraIDsArray }
								)
								// console.log(additionalFactoryItemsResponse);
								setCollectionItems(additionalFactoryItemsResponse)
							}

					*/

          let extraIDsArray = {
            [collectionData.extraIDsIdentifier]: extraIDsArr,
          };
          let scriptName = null;
          if (collectionData.shard) {
            scriptName = "getNFTCatalogItems"; //getShardScriptName(collectionData.shard)
          }

          async function getExtraItems(addr, extraIDsArray) {
            const additionalFactoryItemsResponse = await Script(
              scripts["getNFTCatalogItems"],
              { user: addr, collectionIDs: extraIDsArray }
            );
            // console.log(additionalFactoryItemsResponse);
            setCollectionItems(additionalFactoryItemsResponse);
          }

          try {
            // console.log(extraIDsArray)
            getExtraItems(address, extraIDsArray);
          } catch (error) {
            console.log(error);
          }
        } else {
          setHasMore(true);
          let slicedExtraIDsArray = extraIDsArr.slice(0, loadLength);
          setLoadMoreIDs(extraIDsArr.slice(loadLength, extraIDsArr.length));
          // console.log(extraIDsArr)
          let extraIDsArray = {
            [collectionData.extraIDsIdentifier]: slicedExtraIDsArray,
          };
          let scriptName =
            "getAdditionalFactoryCollectionItems" + collectionData.shard;

          async function getExtraItems(addr, extraIDsArray) {
            const additionalFactoryItemsResponse = await Script(
              scripts[scriptName],
              { user: addr, collectionIDs: extraIDsArray }
            );
            // console.log(additionalFactoryItemsResponse);
            setCollectionItems(additionalFactoryItemsResponse);
          }

          try {
            // console.log(extraIDsArray)
            getExtraItems(address, extraIDsArray);
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
  }, []);

  function handleSelectNft(e) {
    if (lastClicked !== "none") {
      lastClicked.classList.remove("pfp-card-active");
    }
    let docid = document.getElementById("col" + e.target.id);
    // console.log(lastClicked);
    // console.log(docid);
    setLastClicked(docid);
    if (e.target.checked) {
      docid.classList.add("pfp-card-active");
    } else {
      docid.classList.remove("pfp-card-active");
    }
  }

  // useEffect(() => {
  // },[loadMoreIDs])
  // console.log(loadMoreIDs)

  useEffect(() => {
    if (collectionItems && collectionItems[collectionData.extraIDsIdentifier]) {
      setCollection([
        ...collection,
        ...collectionItems[collectionData.extraIDsIdentifier],
      ]);
    }
  }, [collectionItems]);

  function LoadMoreCollection() {
    if (loadMoreIDs.length > 0) {
      if (loadMoreIDs.length < loadLength) {
        setHasMore(false);
        let extraIDsArray = {
          [collectionData.extraIDsIdentifier]: loadMoreIDs,
        };
        let scriptName =
          "getAdditionalFactoryCollectionItems" + collectionData.shard;

        async function getExtraItems(addr, extraIDsArray) {
          const additionalFactoryItemsResponse = await Script(
            scripts[scriptName],
            { user: addr, collectionIDs: extraIDsArray }
          );
          // console.log(additionalFactoryItemsResponse);
          setCollectionItems(additionalFactoryItemsResponse);
        }

        try {
          // console.log(extraIDsArray)
          getExtraItems(address, extraIDsArray);
          setLoadMoreIDs();
        } catch (error) {
          console.log(error);
        }
      } else {
        let extraIDsArray = {
          [collectionData.extraIDsIdentifier]: loadMoreIDs.slice(0, loadLength),
        };
        let scriptName =
          "getAdditionalFactoryCollectionItems" + collectionData.shard;

        async function getExtraItems(addr, extraIDsArray) {
          const additionalFactoryItemsResponse = await Script(
            scripts[scriptName],
            { user: addr, collectionIDs: extraIDsArray }
          );
          // console.log(additionalFactoryItemsResponse);
          setCollectionItems(additionalFactoryItemsResponse);
        }

        try {
          // console.log(extraIDsArray)
          getExtraItems(address, extraIDsArray);
          setLoadMoreIDs(loadMoreIDs.slice(loadLength, loadMoreIDs.length));
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  return (
    <>
      {collection &&
        collection.map((nft, i) => (
          <Col
            key={i}
            className="collection-nft-col mb-3"
            xs="6"
            sm="6"
            lg="4"
            align="center"
          >
            <Form.Check type="radio" id={nft.id} className="p-0 m-0">
              <Form.Check.Label
                id={"col" + nft.id}
                className="form-check-album"
              >
                <CollectionNftPfpSelect
                  nftData={nft}
                  idkey={i}
                  name={name}
                  object={nft}
                  collection={colName}
                  link="disabled"
                />
              </Form.Check.Label>
              <Form.Check.Input
                type="radio"
                hidden
                value={nft.id}
                onClick={(e) => {
                  // console.log(nft);
                  setPfpLink(nft.media);
                  handleSelectNft(e);
                }}
              />
            </Form.Check>
          </Col>
        ))}
      {hasMore && (
        <Row className="my-3">
          <Col align="center">
            <Button
              onClick={() => LoadMoreCollection()}
              variant="find-outline-commerce"
              className="w-100"
            >
              Load More
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}
