import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import "react-image-lightbox/style.css";
import "./nftdetailpage.css";
import { UseThemeStatus } from "../../../../functions/themeMode";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "../../../../functions/script";
import useToastTxStatus from "../../../../functions/useToastTxStatus";
import useListingStatusCollection from "../../../../functions/useListingStatusCollection";
import { useFormStatus } from "../../../../functions/DisabledState";
import ColDetailMediaContent from "./ColDetailMediaContent/ColDetailMediaContent";
import ColDetailNameAndCol from "./ColDetailNameAndCol/ColDetailNameAndCol";
import ColDetailBelowName from "./ColDetailBelowName/ColDetailBelowName";
import ColDetailAccordion from "./ColDetailAccordion/ColDetailAccordion";
import ColDetailShareIcons from "./ColDetailShareIcons/ColDetailShareIcons";
import ColDetailOffers from "./ColDetailOffers/ColDetailOffers";
import ColDetailRoyalties from "./ColDetailRoyalties/ColDetailRoyalties";
import ColDetailBackToCol from "./ColDetailBackToCol/ColDetailBackToCol";
import ColDetailForAuction from "./ColDetailForAuction/ColDetailForAuction";
import ColDetailForSale from "./ColDetailForSale/ColDetailForSale";
import ColDetailLatestBids from "./ColDetailLatestBids/ColDetailLatestBids";
import ColDetailOwnItem from "./ColDetailOwnItem/ColDetailOwnItem";
import ColDetailAvailableOnFlowty from "./ColDetailAvailableOn/ColDetailAvailableOnFlowty";
import ColDetailAvailableOnFlowtyRental from "./ColDetailAvailableOn/ColDetailAvailableOnFlowtyRental";
import ColDetailAvailableOnStorefront from "./ColDetailAvailableOn/ColDetailAvailableOnStorefront";
import ColDetailAvailableOnStorefrontV2 from "./ColDetailAvailableOn/ColDetailAvailableOnStorefrontV2";
import ColDetailAvailableOnFindAuction from "./ColDetailAvailableOn/ColDetailAvailableOnFindAuction";
import ColDetailAvailableOnFindSale from "./ColDetailAvailableOn/ColDetailAvailableOnFindSale";
import ColDetailBurnItem from "./ColDetailOwnItem/ColDetailBurnItem";
import ColDetailRedeemVoucher from "./ColDetailOwnItem/ColDetailRedeemVoucher";
import {
  useUserWalletStatus,
  useWalletType,
} from "../../../../functions/useWalletType";
import Loading from "../../../loading/loading";

export function NftDetailPage(props) {
  const { findUser, profileData } = props;
  const { nftData, searchedAddress, addressNoProfile } = props;

  const [show, setShow] = useState(null);
  const [listingDetails, setListingDetails] = useState({});
  const handleClose = (id) => setShow(id);
  const handleShow = (id) => setShow(id);
  const [buyNowClicked, setBuyNowClicked] = useState(false);
  const [fulfillClicked, setFulfillClicked] = useState(false);
  const [nftDetails, setNftDetails] = useState();
  const [openPackClicked, setOpenPackClicked] = useState(false);
  const [type, setType] = useState();
  const [showSalesModal, setShowSalesModal] = useState(false);
  const { nft, colName, name, walletProvider } = useParams();
  const fclUser = useUserWalletStatus();
  const [profileAddress, setProfileAddress] = useState(null);
  const walletType = useWalletType();

  // let userAddress = profileData ? profileData.profile.address : searchedAddress;

  // TODO: Check that user is not hardcoded and properly resolved
  const user = fclUser.addr;

  const toastStatus = useToastTxStatus();
  const themeStatus = UseThemeStatus();
  const navigate = useNavigate();
  const formStatus = useFormStatus();

  const getNFTDetails = async (user) => {
    const inputArgs = {
      // TODO: Check that user is not hardcoded
      user,
      project: nftData.extraIDsIdentifier,
      id: parseInt(nft),
      views: [],
    };

    const nftDetailResponse = await Script(
      scripts["getNFTDetailsNFTCatalog"],
      inputArgs
    );

    setNftDetails(nftDetailResponse);
  };

  //set the look up address for nftdetails
  useEffect(() => {
    if (profileData) {
      if (walletProvider && walletProvider !== "main") {
        setProfileAddress(profileData.accounts[walletProvider]);
      } else {
        setProfileAddress(profileData.profile.address);
      }
    } else {
      setProfileAddress(searchedAddress);
    }
  }, [walletProvider, profileData]);

  useEffect(() => {
    if (profileAddress) {
      try {
        // console.log(extraIDsArray)
        if (!fulfillClicked || !buyNowClicked || !openPackClicked) {
          // console.log("OPEN");
          // console.log(openPackClicked);
          showSalesModal === true && setShowSalesModal(false);
          getNFTDetails(profileAddress);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [nft, profileAddress]);

  const listingStatus = useListingStatusCollection(
    nftDetails,
    user,
    profileData,
    nftData
  );

  useEffect(() => {
    // I think this may be unnecessary

    // if (listingStatus.itemMetaData) {
    //   if (listingStatus.aCatalogItem) {
    //     let catalogType = listingStatus.itemType;
    //     setType(catalogType);
    //   } else {
    //     let address = listingStatus.itemMetaData.contract.address;
    //     let no0xAddress;
    //     if (address) {
    //       no0xAddress = address.substring(2);
    //     }
    //     if (no0xAddress) {
    //       setType(
    //         "A." + no0xAddress + "." + listingStatus.itemMetaData.contract.name
    //       );
    //     } else {
    //       setType("");
    //     }
    //   }
    // }
    if (listingStatus.itemMetaData?.nftDetail) {
      setType(listingStatus.itemMetaData.nftDetail.type);
    }
  }, [listingStatus]);

  const nftType = nftDetails?.nftDetail?.type;
  const isVoucher = nftType && nftType.includes("NameVoucher.NFT");
  const isOwner = listingStatus?.owner?.ownItem;
  const showRedeemVoucher = isVoucher && isOwner;

  return (
    <Container fluid={+true} id="col-detail-container" className="px-0 mx-0">
      <Card className="p-3" id="col-detail-card">
        <Row id="col-back-to-col-row" className="pb-4">
          <ColDetailBackToCol
            listingStatus={listingStatus}
            colName={colName}
            profile={name}
            walletProvider={walletProvider}
          />
        </Row>

        {nftDetails ? (
          <>
            <Row id="col-media-row" className="mb-2">
              <div id="col-media-col">
                <ColDetailMediaContent listingStatus={listingStatus} />
              </div>
            </Row>

            <Row>
              <Col id="col-detail-left-col" xs="12" lg="6">
                <Row className="">
                  <ColDetailNameAndCol listingStatus={listingStatus} />
                </Row>
                <Row className="my-2 mb-4">
                  <ColDetailBelowName listingStatus={listingStatus} />
                </Row>
                {/* detail acc lg view */}
                <Row className="d-none d-lg-block">
                  <ColDetailAccordion listingStatus={listingStatus} />
                </Row>
              </Col>

              <Col id="col-detail-right-col" xs="12" lg="6">
                {showRedeemVoucher && (
                  <ColDetailRedeemVoucher
                    details={nftDetails?.nftDetail}
                    findUser={findUser}
                    profileData={profileData}
                  />
                )}

                {/* Own item options */}
                {listingStatus.owner.ownItem && (
                  <ColDetailOwnItem
                    listingStatus={listingStatus}
                    colName={colName}
                    openPackClicked={openPackClicked}
                    setOpenPackClicked={setOpenPackClicked}
                    showSalesModal={showSalesModal}
                    setShowSalesModal={setShowSalesModal}
                  />
                )}
                {/* sales status catalog */}
                {listingStatus.allCurrentListings.forAuction.forAuction && (
                  <ColDetailForAuction
                    listingStatus={listingStatus}
                    fulfillClicked={fulfillClicked}
                    setFulfillClicked={setFulfillClicked}
                  />
                )}
                {listingStatus.allCurrentListings.forSale.forSale && (
                  <ColDetailForSale
                    listingStatus={listingStatus}
                    buyNowClicked={buyNowClicked}
                    setBuyNowClicked={setBuyNowClicked}
                  />
                )}
                {listingStatus.allCurrentListings.forAuction.forAuction && (
                  <ColDetailLatestBids listingStatus={listingStatus} />
                )}

                {/* show if item is listed on find market, flowty, flowty loans or storefront  */}
                {listingStatus.allCurrentListings.forSale.forSale && (
                  <ColDetailAvailableOnFindSale listingStatus={listingStatus} />
                )}
                {listingStatus.allCurrentListings.forAuction.forAuction && (
                  <ColDetailAvailableOnFindAuction
                    listingStatus={listingStatus}
                  />
                )}
                {listingStatus.allCurrentListings.flowty && (
                  <ColDetailAvailableOnFlowty listingStatus={listingStatus} />
                )}
                {listingStatus.allCurrentListings.flowtyRental && (
                  <ColDetailAvailableOnFlowtyRental
                    listingStatus={listingStatus}
                  />
                )}
                {listingStatus.allCurrentListings.storefront && (
                  <ColDetailAvailableOnStorefront
                    listingStatus={listingStatus}
                  />
                )}
                {listingStatus.allCurrentListings.storefrontV2 && (
                  <ColDetailAvailableOnStorefrontV2
                    listingStatus={listingStatus}
                  />
                )}

                {/* show offers if catalog item */}
                {listingStatus.allowedListingActions &&
                  Object.keys(listingStatus.allowedListingActions).length > 0 &&
                  listingStatus.allowedListingActions !== "none" && (
                    <Row>
                      <Col>
                        <ColDetailOffers listingStatus={listingStatus} />
                      </Col>
                    </Row>
                  )}

                {/* show royalties if catalog item */}
                {Object.keys(listingStatus.itemRoyalties).length > 0 && (
                  <Row>
                    <Col>
                      <ColDetailRoyalties listingStatus={listingStatus} />
                    </Col>
                  </Row>
                )}

                {/* Burn buttons mobile / desktop - currently disabled after the great pie burn of 12/22 */}
                {/* Currently reenabled - after 1700 will be disabled just for partyfavorz */}
                {listingStatus.owner.ownItem &&
                  listingStatus.aCatalogItem &&
                  walletType !== "Dapper Wallet" && (
                    <Row className="d-none d-lg-block">
                      <Col align="center">
                        <ColDetailBurnItem listingStatus={listingStatus} />
                      </Col>
                    </Row>
                  )}
              </Col>

              {listingStatus.owner.ownItem &&
                listingStatus.aCatalogItem &&
                walletType !== "Dapper Wallet" && (
                  <Row className="d-block d-lg-none">
                    <Col align="center">
                      <ColDetailBurnItem listingStatus={listingStatus} />
                    </Col>
                  </Row>
                )}

              {/* detail acc mobile */}
              <Row className="d-block d-lg-none">
                <ColDetailAccordion listingStatus={listingStatus} />
              </Row>
              <Row className="pb-4 ">
                <Col className="">
                  <ColDetailShareIcons />
                </Col>
              </Row>
            </Row>
          </>
        ) : (
          // ! we can put a skeleton loading element here
          <Loading />
        )}
      </Card>
    </Container>
  );
}
