import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, Col, Spinner } from "react-bootstrap";
import ReactGA from "react-ga4";
import { UseThemeStatus } from "../../../../../functions/themeMode";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import { useNavigate } from "react-router-dom";
import { Script, Tx } from "../../../../../functions/script";
import { scripts, transactions } from "@findonflow/find-flow-contracts";
import "./voucher.scss";

const BASE_VIEW = "base";
const IN_FLIGHT = "in-flight";
const SUBMITTED = "submitted";

function getNameInfo(name) {
  return Script(scripts.getNameSearchbar, { name });
}
async function claimName(id, name, callbacks) {
  const { onSuccess } = callbacks;
  try {
    await Tx({
      tx: transactions.redeemNameVoucher,
      args: { id, name },
      callbacks: {
        ...callbacks,
        async onSuccess() {
          ReactGA.event({
            category: "User",
            action: "Name Voucher Redeemed",
            label: "Profile",
          });
          onSuccess();
        },
      },
      skip: {
        loading: true,
        start: true,
        submit: true,
      },
    });
  } catch (error) {
    console.error("Claim Name Failed", error);
  }
}

function SmallLoading() {
  return (
    <Spinner
      animation="grow"
      role="status"
      style={{ color: "var(--theme-main)" }}
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}
function Hint(props) {
  const { children, show } = props;
  if (!show) {
    return null;
  }
  return <p className={"smallTextSpan red my-0"}>{children}</p>;
}

function validate(query) {}

function SearchBar(props) {
  const { id, minCharacters, ownLeases, query } = props;
  const { onChange } = props;
  const { callbacks } = props;

  // State
  const nameInput = useRef();
  const [loading, setLoading] = useState(false);
  const [nameInfo, setNameInfo] = useState({});

  // Computed
  const { status } = nameInfo;

  const notEmpty = query !== "";
  const lessThanMax = query.length <= 16;
  const moreThanMin = query.length >= minCharacters;
  const validLength = moreThanMin && lessThanMax;
  const nameIsTaken = status === "TAKEN";
  const nameIsFree = status === "FREE";

  // Conditionals
  const owned = ownLeases[query];
  const showNameTaken = validLength && nameIsTaken;
  const claimEnabled = validLength && nameIsFree && !loading && !owned;
  const showMinimum = !moreThanMin && notEmpty;
  const validName = /^[a-z0-9-]{0,16}$/.test(query);

  const showValidName = !validName;

  // Effects
  useEffect(() => {
    if (validLength && validName && !owned) {
      setLoading(true);
      getNameInfo(query).then((nameInfo) => {
        setNameInfo(nameInfo);
        setLoading(false);
      });
    }
  }, [query]);

  useEffect(() => {
    nameInput.current.focus();
  }, [nameInput.current]);

  // Conditional Compute
  let claimTitle = `Claim ${query}.find name`;
  switch (true) {
    case query === "":
      claimTitle = "Name can't be empty";
      break;
    case showNameTaken:
      claimTitle = "You can't claim this name";
      break;
    case owned:
      claimTitle = "You already own this name";
      break;
    default:
      break;
  }

  // Render
  return (
    <div className="mb-4">
      <div
        className={"flex-row align-items-center justify-content-between mb-1"}
      >
        <div className="w-75 pe-5">
          <input
            className={"px-2 py-1 w-100 form-control"}
            type={"text"}
            value={query}
            onChange={onChange}
            placeholder={"Enter name you want to claim"}
            ref={nameInput}
          />
        </div>
        <div className={"w-25 flex-center"}>
          {loading && <SmallLoading />}
          {!loading && (
            <Button
              disabled={!claimEnabled}
              title={claimTitle}
              variant="find"
              className={"py-1 px-3 h-auto fs-6 w-100"}
              onClick={() => claimName(id, query, callbacks)}
            >
              Claim
            </Button>
          )}
        </div>
      </div>
      <div className={"mb-2"}>
        <Hint show={showNameTaken}>
          This name is taken, please try different one
        </Hint>
        <Hint
          show={showMinimum && !owned}
        >{`Minimum ${minCharacters} characters`}</Hint>
        <Hint show={owned}>{`You already own this name 😉`}</Hint>
        <Hint
          show={!lessThanMax}
        >{`Name should be less than 16 characters long`}</Hint>
        <Hint
          show={showValidName}
        >{`Lowercase letters, numbers and "-" only`}</Hint>
      </div>
    </div>
  );
}

function MountModal(props) {
  const { showModal, setShowModal } = props;
  const { details, profileData } = props;

  const [progress, setProgress] = useState(BASE_VIEW);
  const [query, setQuery] = useState("");

  const navigate = useNavigate();

  const data = extractData(details, profileData);

  const { minCharacters, leases, id } = data;
  const modalTitle =
    progress === BASE_VIEW
      ? `Redeem ${minCharacters}-Letter Voucher`
      : "Processing Request";

  const ownLeases = useMemo(() => {
    return leases.reduce((result, lease) => {
      result[lease.name] = true;

      return result;
    }, {});
  }, [leases]);

  const callbacks = {
    onStart: () => {
      setProgress(IN_FLIGHT);
    },
    onSubmission: () => {
      setProgress(SUBMITTED);
    },
    onSuccess: () => navigate("/me/names"),
    onError: () => {
      setProgress(BASE_VIEW);
    },
  };

  const Claim = (props) => {
    const { show, query, onChange } = props;
    const showToggle = show ? "d-block" : "d-none";

    const activeLeases = leases
      .sort((a, b) => {
        return a.validUntil - b.validUntil;
      })
      .filter((lease) => {
        if (query === "") {
          return true;
        }
        return lease.name.includes(query);
      })
      .slice(0, 5);

    const subheader =
      query === ""
        ? "Top 5 of your leases that will expire first"
        : "Filtered leases that will expire first";

    return (
      <div className={showToggle}>
        <h4>Claim New Name</h4>
        <SearchBar
          id={id}
          minCharacters={minCharacters}
          ownLeases={ownLeases}
          callbacks={callbacks}
          query={query}
          onChange={onChange}
        />

        {activeLeases.length > 0 && (
          <>
            <hr />
            <h4 className={"my-0 text-center mt-2"}>Active Names</h4>
            <p className={"smallTextSpan my-0 mb-3 text-center"}>{subheader}</p>
            {activeLeases.map((lease) => {
              const { name, expireDate } = lease;
              const disabled = name.length < minCharacters;
              const label = disabled ? "--" : "Extend";
              const title = disabled
                ? "You can't use this voucher to extend this name"
                : `Extend "${name}" for another year`;

              return (
                <div
                  className="flex-row align-items-center justify-content-between mb-2"
                  title={title}
                >
                  <div>
                    <p className={"my-0 me-2 fw-bold w-25"}>
                      <span className="themeColor">{name}</span>.find
                    </p>
                    <p className={"my-0 text-start flex-fill smallTextSpan"}>
                      Valid Until:
                      <span className={"ms-1 fw-bold"}>{expireDate}</span>
                    </p>
                  </div>
                  <Button
                    variant="find"
                    className={"py-1 px-3 h-auto fs-6 w-25"}
                    onClick={() => claimName(id, name, callbacks)}
                    disabled={disabled}
                  >
                    {label}
                  </Button>
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  };
  const Wait = () => {
    const label =
      progress === SUBMITTED
        ? "Transaction submitted! Waiting for it to be sealed..."
        : "Submitting transaction...";

    return (
      <Col className="d-flex flex-column align-items-center justify-content-center p-3 popup-progress-overlay">
        <SmallLoading />
        <p className={"small my-3"}>{label}</p>
      </Col>
    );
  };

  const showUI = progress === BASE_VIEW;
  const onHide = () => showUI && setShowModal(false);
  return (
    <Modal
      id="redeem-voucher-modal"
      centered
      show={showModal}
      onHide={onHide}
      data-theme={UseThemeStatus()}
      className="popup-container"
    >
      <Modal.Header
        closeLabel="close window"
        style={{ border: "none" }}
        closeButton={showUI}
      >
        <Modal.Title className="text-center w-100">
          <h4 className="fw-bold" style={{ marginBottom: "0px" }}>
            {modalTitle}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Claim
          show={showUI}
          query={query}
          onChange={(event) => {
            const query = event.target.value.toLowerCase();
            setQuery(query);
          }}
        />
        {!showUI && <Wait />}
      </Modal.Body>
    </Modal>
  );
}

function formatTimestamp(timestamp) {
  return new Date(Number(timestamp * 1000)).toLocaleString();
}
function extractData(details, profileData) {
  const { traits, id, thumbnail } = details;

  const minCharacters = traits[0].value;
  const thumbUrl = parseImgUrl(thumbnail, "thumbnail");

  const leases = profileData.leases.map((lease) => {
    const { name, validUntil, status } = lease;
    return {
      name,
      status,
      validUntil,
      expireDate: formatTimestamp(validUntil),
    };
  });

  return {
    id,
    minCharacters,
    thumbUrl,
    leases,
  };
}

export default function ColDetailRedeemVoucher(props) {
  const { details, profileData, findUser } = props;
  const [showModal, setShowModal] = useState(false);

  const noData = !details || !profileData;

  if (noData) {
    return null;
  }

  return (
    <div id="redeem-voucher-wrapper">
      <Button
        className="w-100 mb-3"
        variant="find"
        onClick={() => setShowModal(true)}
      >
        Redeem Voucher
      </Button>

      <MountModal
        showModal={showModal}
        setShowModal={setShowModal}
        {...props}
      />
    </div>
  );
}
