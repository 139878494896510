import React from "react";
import { Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router";
import { transactions } from "@findonflow/find-flow-contracts";
import { Tx } from "../../functions/script";

const purchasePack = async (params) => {
  const { totalAmount, packId, colName, numOfPacks } = params;
  const { onSuccess } = params;
  try {
    await Tx({
      tx: transactions.buyFindPack,
      args: {
        packTypeName: colName,
        packTypeId: packId,
        numberOfPacks: numOfPacks,
        totalAmount,
      },
      callbacks: { onSuccess },
    });
  } catch (e) {
    console.log(e);
  }
};

export default function DropPageMintBoxMint(props) {
  const { packDetails, numOfPacks, setNumOfPacks } = props;
  const navigate = useNavigate();
  let { colName, packId } = useParams();

  const price = packDetails?.saleInfos[0]?.price;
  const totalAmount = numOfPacks * price;
  const onSuccess = async () => {
    console.log("success");
    navigate("/me/collection/main/FindPack");
  };
  const params = {
    colName: colName.toLowerCase(),
    totalAmount,
    packId,
    numOfPacks,
    onSuccess,
  };

  return (
    <div className="drop-page-mint-box-mint-wrapper" id="claim-block">
      <div>
        <span className="mint-box-subtitle-span">Drop {packId}</span>
      </div>
      {packDetails && <div className="drop-page-mint-box-mint-purchase-span">Purchase</div>}
      <div className="mint-box-drop-name"id="drop-info">Party Favors X Afterfuture</div>
      {packDetails && (
        <>
          <div className="drop-page-mint-box-mint-price-span">
            <span className={"me-2"}>Price:</span>
            <span className={"me-2"}>{packDetails.saleInfos[0].price * 1}</span>
            <span>{packDetails.walletAlias.toUpperCase()}</span>
            <span className="packs-available-span">
              Packs Available {packDetails.packsLeft}
            </span>
          </div>
        </>
      )}

      {!packDetails && (
        <div>
          <span className="mint-box-subtitle-span">
            Connect your wallet to collect some awesome NFTs
          </span>
        </div>
      )}

      {packDetails &&
        packDetails.packsLeft > 0 &&
        packDetails.saleEnded === false && (
          <div className="mint-box-button-row">
            <div className="mint-box-button-row-col">
              <select onChange={(e) => setNumOfPacks(e.target.value)}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="mint-box-button-row-col">
              <Button
                onClick={() => purchasePack(params)}
                variant="find-claim-pack-yellow"
              >
                Buy
              </Button>
            </div>
          </div>
        )}
      {/* <div className="drop-page-mint-box-mint-price-span">
        Things got a bit crusty bros.
      </div>
      <div className="drop-page-mint-box-mint-price-span">
        We have disabled sales until we fix it.{" "}
      </div> */}
    </div>
  );
}
