import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useFormStatus, useStateChanged } from "../../functions/DisabledState";
import { Script } from "../../functions/script";
import { scripts } from "@findonflow/find-flow-contracts";
import { CollectionTabPage } from "../profile/profilecollection/collectiontabpage/collectiontabpage";
import { CollectionNft } from "../profile/profilecollection/collectionnft/collectionnft";
import { handleSetPfp } from "../../functions/flow-interactions/transactions";
import "./editprofilesettings.css";
import { UseThemeStatus } from "../../functions/themeMode";
import useGetFactoryCollections from "../../functions/useGetFactoryCollections";
import { PfpSelectCollectionTabPage } from "./pfpselectcollectiontabpage";
import { useSwipeable } from "react-swipeable";

const PfpSelectSettings = ({ findUser }) => {
  const [show, setShow] = useState(false);
  const [pfpLink, setPfpLink] = useState("");
  const [lastClicked, setLastClicked] = useState("none");
  const [colName, setColName] = useState("");
  let currentTheme = UseThemeStatus();
  const userCollections = useGetFactoryCollections(findUser.address);
  let collectionItems = userCollections.collectionItems;
  // console.log(collectionItems);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  // console.log(pfpLink);

  useEffect(() => {
    if (collectionItems && collectionItems !== {}) {
      // console.log(collectionItems);
      if (!colName) {
        setColName(Object.keys(collectionItems)[0]);
      }
    }
  }, [userCollections]);

  // //console.log(colName)
  // //console.log(Object.keys(collectionItems.collections)[0])
  // //console.log(collectionItems.collections)

  function handleSelectNft(e) {
    if (lastClicked !== "none") {
      lastClicked.classList.remove("pfp-card-active");
    }
    let docid = document.getElementById("col" + e.target.id);
    setLastClicked(docid);
    if (e.target.checked) {
      docid.classList.add("pfp-card-active");
    } else {
      docid.classList.remove("pfp-card-active");
    }
  }

  const settingsSetPfp = () => {
    let imgUrl;
    if (pfpLink.includes("ipfs://")) {
      imgUrl = pfpLink.replace("ipfs://", "https://find.mypinata.cloud/ipfs/");
    } else {
      imgUrl = pfpLink;
    }
    handleSetPfp(imgUrl);
  };
    //mobile swipedown close

  const config = {
    delta: 150, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true,
    swipeDuration: 250,
  };
  const handlers = useSwipeable({
    onSwipedDown: () => {
      // console.log('this')
      setShow(false);
    },
    ...config,
  });

  return (
    <>
      <Col>
        <Button variant="find-text" onClick={handleShow}>
          <small>Choose one of your NFTs as your profile picture</small>
        </Button>
      </Col>

      <Modal
        data-theme={currentTheme}
        show={show}
        onHide={handleClose}
        size="lg"
        scrollable
      >
        <div {...handlers}>
          <Modal.Header style={{ border: "none" }} closeButton>
            <div className="name" align="center">
              <h3> Select a profile picture</h3>
            </div>
          </Modal.Header>
          <Modal.Body>
            <fieldset id="a" disabled={useFormStatus()}>
              <Container className="g-0">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Row className="my-3">
                    <Col align="center">
                      <p>
                        You can choose any NFTs from your collection to use as a
                        profile picture.
                      </p>
                      <p>
                        Click the NFT you would like to use as your profile
                        picture and then press 'Set as PFP'.
                      </p>
                    </Col>
                  </Row>

                  {collectionItems &&
                    collectionItems !== "first_init" &&
                    collectionItems !== "" &&
                    Object.keys(collectionItems).length > 0 && (
                      <Row className="my-4 px-3 align-items-center justify-content-between">
                        <Col xs="12" md="auto" className="align-items-start">
                          <small>Viewing {colName} NFTs</small>
                        </Col>
                        <Col xs="12" md="auto" className="align-items-end ">
                          <Form.Select
                            onChange={(k) => setColName(k.target.value)}
                            id="selectCollection"
                            aria-label="Collection Select"
                            className=""
                            style={{ height: "52px" }}
                          >
                            {collectionItems &&
                              collectionItems !== "first_init" &&
                              collectionItems !== "" &&
                              Object.keys(collectionItems).length > 0 &&
                              Object.keys(collectionItems).map(
                                (collection, i) => {
                                  if (collectionItems[collection].length > 0) {
                                    return (
                                      <option
                                        key={i}
                                        value={collection}
                                        selected={
                                          collection === colName ? true : false
                                        }
                                      >
                                        {collection +
                                          " (" +
                                          collectionItems[collection].length +
                                          ")"}
                                      </option>
                                    );
                                  }
                                }
                              )}
                          </Form.Select>
                        </Col>
                      </Row>
                    )}

                  <Tabs
                    activeKey={colName}
                    className="collection-tabs ps-3"
                    id="collection-tabs"
                    // onSelect={(k) => handleChangeCollection(k)}
                  >
                    {collectionItems &&
                      collectionItems !== "first_init" &&
                      collectionItems !== "" &&
                      Object.keys(collectionItems).length > 0 &&
                      Object.keys(collectionItems).map((collection, i) => {
                        if (collectionItems[collection].length > 0) {
                          let nfts = [collection];
                          return (
                            <Tab
                              eventKey={collection}
                              mountOnEnter
                              title={collection.replace(/[-_]/g, " ")}
                              key={i}
                            >
                              <Row className="justify-content-start hits-row px-2">
                                <PfpSelectCollectionTabPage
                                  nfts={nfts}
                                  collectionData={collectionItems[collection]}
                                  // name={name}
                                  address={findUser.address}
                                  pfpLink={pfpLink}
                                  setPfpLink={setPfpLink}
                                  handleSelectNft={handleSelectNft}
                                  colName={colName}
                                />
                                {/* <CollectionTabPage
                          nfts={nfts}
                          collectionData={collectionItems[collection]}
                          // name={name}
                          address={findUser.address}
                        /> */}
                              </Row>
                            </Tab>
                          );
                        } else {
                          return (
                            <Tab
                              eventKey={collection}
                              mountOnEnter
                              title={collection.replace(/[-_]/g, " ")}
                              key={i}
                            >
                              <Row className="justify-content-center">
                                <Col align="center" className="px-5 pt-4">
                                  <Image
                                    className=""
                                    src="/assets/img/profile/collection/no-nfts.webp"
                                    fluid={+true}
                                  ></Image>
                                  <div className="my-3">
                                    <h4>
                                      Uh oh, there are currently no NFTs in this
                                      collection.
                                    </h4>
                                  </div>
                                  <div className="mb-5">
                                    Some NFTs aren’t supported yet. When we
                                    integrate with them, they will appear here.
                                  </div>
                                </Col>
                              </Row>
                            </Tab>
                          );
                        }
                      })}
                  </Tabs>

                  {/* {console.log(colName)} */}
                  {/* 
                {colName && (
                  <Row className="mt-5 d-flex justify-content-center">
                    {collectionItems &&
                    collectionItems !== "first_init" &&
                    collectionItems !== "" &&
                    Object.keys(collectionItems).length > 0 ? (
                      Object.keys(collectionItems[colName].items).map(
                        (nft, i) => {
                          // console.log(collectionItems[colName].items[nft]) 
                          return (
                            <Col key={i} className="mb-3" xs="auto">
                              <Form.Check
                                type="radio"
                                id={nft}
                                className="p-0 m-0"
                              >
                                <Form.Check.Label
                                  id={"col" + nft}
                                  className="form-check-album"
                                >
                                  <CollectionNft
                                    nftData={
                                      collectionItems[colName].items[nft]
                                    }
                                    idkey={i}
                                    object={nft}
                                    key={i}
                                    link="disabled"
                                    pfp={true}
                                  />
                                </Form.Check.Label>
                                <Form.Check.Input
                                  type="radio"
                                  hidden
                                  value={nft}
                                  onClick={(e) => {
                                    setPfpLink(
                                      collectionItems[colName].items[nft].media
                                    );
                                    handleSelectNft(e);
                                  }}
                                />
                              </Form.Check>
                            </Col>
                          );
                        }
                      )
                    ) : (
                      <Col align="center" className="px-5">
                        <Image
                          className=""
                          src="/assets/img/profile/collection/no-nfts.webp"
                          fluid={+true}
                        ></Image>
                        <div className="my-3">
                          <h4>
                            Uh oh, there are currently no NFTs in this
                            collection.
                          </h4>
                        </div>
                        <div className="mb-5">
                          Some NFTs arent supported yet. When we integrate with
                          them they will appear here.
                        </div>
                      </Col>
                    )}
                  </Row>
                )} */}
                </Form>
              </Container>
            </fieldset>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <fieldset id="b" disabled={useFormStatus()}>
              {collectionItems &&
                collectionItems !== "first_init" &&
                collectionItems !== "" &&
                Object.keys(collectionItems).length > 0 && (
                  <Button
                    onClick={() => settingsSetPfp()}
                    variant="find-nochange-dark"
                  >
                    Set as PFP
                  </Button>
                )}
            </fieldset>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default PfpSelectSettings;
