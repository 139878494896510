import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Row, Col, Image, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useImmer } from "use-immer";
import { useFormStatus } from "../../../../functions/DisabledState";
import { UseThemeStatus } from "../../../../functions/themeMode";
import {
  handleBidNFTAuction,
  handleIncreaseBidNFTAuction,
  handleBid,
  handleIncreaseBid,
} from "../../../../functions/flow-interactions/transactions";
import useProfileLookup from "../../../../functions/useProfileLookup";
import FlowSticker from "../../ftstickers/FlowSticker";
import FUSDSticker from "../../ftstickers/FUSDSticker";
import "./makebidnftmodalmp.css";
import { scripts } from "@findonflow/find-flow-contracts";
import { Script } from "../../../../functions/script";
import parseImgUrl from "../../../../functions/parseImgUrl";

export default function MakeBidNftMpModal(props){

  const { user, userHighestBidder, profileDetails } = props
  const { isName, hit, latestBid } = props
  const { nftDetails, listingDetails, listingStatus } = props;

  const [updatedProfile, setUpdatedProfile] = useState(null)
  const [profileAddress, setProfileAddress] = useState(null)

useEffect(()=> {
  if (!profileAddress && latestBid){
    setProfileAddress(latestBid.blockEventData.buyer)
  }

}, [latestBid]

)

  // let profileAddress = latestBid.blockEventData.account

  useEffect(() => {
    if (profileAddress) {
      // console.log(profileAddress)
        async function getProfile(addr) {
          const profileResponse = await Script(scripts.getProfile, { user: addr });
          // console.log(profileResponse)
          setUpdatedProfile(profileResponse)
        }
        try {
          getProfile(profileAddress);
        } catch (error) {
          console.log(error);
        }
    }
  }, [profileAddress])



  // //console.log(nftDetails);
  // //console.log(profileDetails);
  // console.log(listingDetails);
  //console.log(listingDetails);
  //console.log(user);
  // console.log(listingDetails);
  // console.log(latestBid);

  // console.log(listingDetails);

  // const updatedProfile = useProfileLookup(
  //   listingDetails.bidder ? listingDetails.bidder : listingDetails.latestBidBy
  // );

  // console.log(updatedProfile);

  let auctionEndsDate;
  let latestBidderName;
  let latestBidderAddress;

  if (listingStatus && listingStatus.auctionEndsAt) {
    auctionEndsDate = new Date(
      listingStatus.auctionEndsAt * 1000
    ).toLocaleString();

    if (!isName) {
      if (listingDetails.bidderName) {
        latestBidderName = listingDetails.bidderName;
      }
      latestBidderAddress = listingDetails.bidder;
    } else {
      latestBidderAddress = listingDetails.latestBidBy;
    }
  }

  // console.log(auctionEndsDate)

  let validated = false;
  const [formValues, setFormValues] = useImmer([
    {
      id: "address",
      value: profileDetails.address,
    },
    {
      id: "amount",
      value: "0",
    },

    {
      id: "id",
      value: null,
    },
  ]);

  // //console.log(formValues[1].value)
  // //console.log(listingDetails.amount)

  const handleSubmit = (e) => {
    // console.log("hit");
    e.preventDefault();

    if (!isName) {
      if (
        user &&
        user.addr === listingDetails.bidder &&
        formValues[1].value * 1 > listingDetails.auction.currentPrice * 1
      ) {
        e.preventDefault();
        handleIncreaseBidNFTAuction([
          {
            id: "amount",
            value:
              formValues[1].value * 1 - listingDetails.auction.currentPrice * 1,
          },
          {
            id: "id",
            value: nftDetails.uuid,
          },
        ]);
      } else if (
        (!user || user.addr !== listingDetails.bidder) &&
        ((!listingDetails.bidder &&
          formValues[1].value * 1 >= listingDetails.auction.currentPrice * 1) ||
          (listingDetails.bidder &&
            formValues[1].value * 1 > listingDetails.auction.currentPrice * 1))
      ) {
        e.preventDefault();
        handleBidNFTAuction(formValues);
      }
    } else if (isName) {
      if (
        user &&
        user.addr === listingDetails.latestBidBy &&
        formValues[1].value * 1 > listingDetails.latestBid * 1
      ) {
        e.preventDefault();
        handleIncreaseBid([
          {
            id: "bidAmt",
            value: formValues[1].value * 1 - listingDetails.latestBid * 1,
          },
          {
            id: "name",
            value: hit.nft_name,
          },
        ]);
      } else if (
        ((!user || user.addr !== listingDetails.latestBidBy) &&
          listingDetails.auctionEnds &&
          formValues[1].value * 1 > listingDetails.latestBid * 1) ||
        (!listingDetails.auctionEnds &&
          formValues[1].value * 1 >= listingDetails.auctionStartPrice * 1)
      ) {
        e.preventDefault();
        handleBid([
          {
            id: "bidAmt",
            value: formValues[1].value,
          },
          {
            id: "name",
            value: hit.nft_name,
          },
        ]);
      } else if (formValues[1].value * 1 <= listingDetails.latestBid * 1) {
        document.getElementById("amount").classList.add("is-invalid");
        document.getElementById("amount").classList.add("is-valid");
        toast.error(
          `Please enter an amount greater than the current bid of ${
            listingDetails.latestBid * 1
          }`
        );
      } else {
        e.preventDefault();
      }
    }
  };

  //  if (
  //     !listingDetails.bidder &&
  //     parseFloat(formValues[1].value).toFixed(2) >=
  //       parseFloat(listingDetails.amount).toFixed(2)
  //   ) {
  //     e.preventDefault();
  //     handleBidNFTAuction(formValues);
  //   } else if (
  //     listingDetails.bidder &&
  //     formValues[1].value > listingDetails.amount &&
  //     profileDetails.address !== listingDetails.bidder
  //   ) {
  //     handleBidNFTAuction(formValues);
  //   } else if (
  //     profileDetails.address === listingDetails.bidder &&
  //     formValues[1].value > listingDetails.amount
  //   ) {
  //     e.preventDefault();
  //     handleIncreaseBidNFTAuction([
  //       {
  //         id: "id",
  //         value: nftDetails.uuid,
  //       },
  //       {
  //         id: "amount",
  //         value: formValues[1].value - listingDetails.amount,
  //       },
  //     ]);
  //   } else {
  //     e.preventDefault();
  //   }

  // console.log(formValues);
  // console.log(listingDetails);

  useEffect(() => {
    if (listingDetails) {
      setFormValues((draft) => {
        const varVal = draft.find((varVal) => varVal.id === "id");
        varVal.value = listingDetails.listingId;
      });
    }
    // //console.log(formValues);
  }, [listingDetails, setFormValues]);

  function updateField(e) {
    setFormValues((draft) => {
      const varVal = draft.find((varVal) => varVal.id === e.target.name);
      varVal.value = e.target.value;
      //now validate

      if (!isName) {
        if (
          varVal.value * 1 < 1 * 1 ||
          (listingDetails.bidder &&
            varVal.value * 1 <= listingDetails.amount * 1) ||
          (!listingDetails.bidder && varVal.value < listingDetails.amount * 1)
        ) {
          e.target.classList.add("is-invalid");
          e.target.classList.remove("is-valid");
        } else {
          e.target.classList.add("is-valid");
          e.target.classList.remove("is-invalid");
        }
      } else if (isName) {
        if (
          varVal.value * 1 < 1 * 1 ||
          (listingDetails.latestBid &&
            varVal.value * 1 <= listingDetails.latestBid * 1) ||
          (!listingDetails.latestBid &&
            varVal.value < listingDetails.auctionStartPrice * 1)
        ) {
          e.target.classList.add("is-invalid");
          e.target.classList.remove("is-valid");
        } else {
          e.target.classList.add("is-valid");
          e.target.classList.remove("is-invalid");
        }
      }
    });

    // console.log(formValues);
  }

  // console.log(listingStatus);

  return (
    <div
      id="list-name"
      data-theme={UseThemeStatus()}
      className="form-inputs mp-bid-modal"
    >
      <Image
                    crossOrigin="anonymous"
        fluid={+true}
        className="modal-img"
        src={
          isName
          ? parseImgUrl("/assets/img/marketplace/find_name_with_find.png", '', hit.nft_name)
          : parseImgUrl(hit.nft_thumbnail, 'thumbnail')
        }
      />

      <Row className="pt-3 fw-bold">
        <Col align="left" className="">
          Current bid:{" "}
        </Col>
        <Col align="right">
          {isName &&
            (listingDetails.latestBidBy
              ? listingDetails.latestBid * 1 + " FUSD"
              : listingDetails.auctionStartPrice * 1 + " FUSD")}

          {!isName && listingDetails.amount * 1 + " " + listingDetails.ftAlias}
        </Col>
      </Row>

      {!listingStatus.auctionStarted ? (
        <Row className="align-items-center p-3">
          <Col
            style={{ marginRight: "5px" }}
            align="left"
            className="profileMenuPic col-2"
          >
            <Image               crossOrigin="anonymous"
 src={profileDetails.avatar} fluid={+true} />
          </Col>
          <Col align="left">
            <Row>
              <span>Owned by</span>
            </Row>
            <Row className="find-text">
              <>
                {profileDetails &&
                  (profileDetails.findName ? (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={"/" + profileDetails.findName}
                    >
                      <span className="fw-bold">
                        <span className="themeColor">
                          {profileDetails.findName}
                        </span>
                        .find
                      </span>
                    </Link>
                  ) : (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={"/" + profileDetails.address}
                    >
                      <span className="fw-bold">{profileDetails.address}</span>
                    </Link>
                  ))}
              </>
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="align-items-center p-3">
            <Col
              style={{ marginRight: "5px" }}
              align="left"
              className="profileMenuPic col-2"
            >
              <Image
                src={
                  updatedProfile
                    ? updatedProfile.avatar
                    : profileDetails.avatar
                }
                fluid={+true}
              />
            </Col>
            <Col align="left">
              <Row>
                <span>
                  Bid placed by{" "}
                  {updatedProfile && (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={
                        updatedProfile.findName
                          ? "/" + updatedProfile.findName
                          : "/" + updatedProfile.address
                      }
                    >
                      {updatedProfile.findName ? (
                        <span className="fw-bold">
                          <span className="themeColor">{updatedProfile.findName}</span>
                          .find
                        </span>
                      ) : (
                        <span className="fw-bold">
                          {updatedProfile.address}
                        </span>
                      )}
                    </Link>
                  )}
                </span>
              </Row>
              <Row className="find-text">
                {latestBid ? (
                  <>
                    <Col className="secColor small">
                      {new Date(latestBid.eventDate).toLocaleDateString()} -{" "}
                      {new Date(latestBid.eventDate).toLocaleTimeString()}{" "}
                    </Col>
                  </>
                ) : (
                  <>
                    <Col className="secColor small">
                      Bid made on .find market
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>Auction ends on {auctionEndsDate}</Col>
          </Row>
        </>
      )}

      {userHighestBidder && (
        <Row>
          <Col>You are the highest bidder</Col>
        </Row>
      )}

      <Row className="pt-3">
        <Col align="left" className="fw-bold">
          How much would you like to bid?
        </Col>
        <Col>
          {" "}
          <Col>
            {hit.amount_alias === "FlowToken" && <FlowSticker />}
            {hit.amount_alias === "FUSD" && <FUSDSticker />}
          </Col>
        </Col>
        <Row>
          <Form
            disabled={useFormStatus()}
            noValidate
            validated={validated}
            className="form-inputs pt-3"
            onSubmit={handleSubmit}
          >
            <fieldset disabled={useFormStatus()}>
              <Row>
                <Form.Label>Bid amount:</Form.Label>
              </Row>
              <Row className="p-3">
                <Col xs="12" md="6">
                  <Form.Control
                    id="amount"
                    type="number"
                    placeholder="Enter your bid"
                    onChange={updateField}
                    name="amount"
                    min="0"
                    required
                  />
                </Col>
                <Col className="mt-2 mt-md-0" xs="12" md="6">
                  <Button
                    className="w-100"
                    variant="find-outline-commerce-dark"
                    type="submit"
                  >
                    Make Bid
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </Row>
      </Row>
    </div>
  );
};
