import {
  createElement,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { render } from "react-dom";
import { useNavigate } from "react-router";
import ReactGA from "react-ga";
import { usePagination, useSearchBox } from "react-instantsearch-hooks-web";
import { scripts } from "@findonflow/find-flow-contracts";

import { autocomplete } from "@algolia/autocomplete-js";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { debounce } from "@algolia/autocomplete-shared";

import MarketplaceItem from "./MarketplaceItem";
import NameItem from "./NameItem";

import { Script, Tx } from "../../../functions/script";
import { useWalletType } from "../../../functions/useWalletType";
import {handleRegisterName} from "../../../functions/flow-interactions/transactions";

export function Autocomplete(props) {
  const { searchClient, className, ...autocompleteProps } = props;
  const autocompleteContainer = useRef(null);

  let navigate = useNavigate();
  const { query, refine: setQuery } = useSearchBox();
  const { refine: setPage } = usePagination();
  const wallet = useWalletType();

  const facetFilters =
    wallet === "none"
      ? []
      : wallet === "Dapper Wallet"
      ? ["tenant:-find"]
      : ["tenant:-find_dapper"];

  const [instantSearchUiState, setInstantSearchUiState] = useState ({ query });
  const debouncedSetInstantSearchUiState = debounce(
    setInstantSearchUiState,
    500
  );

  async function getFindName(searchName) {
    const name = searchName.toLowerCase()
    return Script(scripts.getNameSearchbar, { name });
  }

  //@ts-ignore
  const handleRegister = async (args, walletType) => {

    const after = ()=> {
      navigate("/me/names");
      window.scrollTo(0, 0);
    }

    await handleRegisterName(args, walletType, after)
  }

  useEffect(() => {
    setQuery(instantSearchUiState.query);
    setPage(0);
  }, [instantSearchUiState]);

  const plugins = useMemo(() => {
    async function fetchHit(query) {
      let findName = await getFindName(query);
      return [
        {
          name: query,
          findName
        },
      ];
    }

    const querySuggestionsInNamesCategory = createQuerySuggestionsPlugin({
      searchClient,
      indexName: "market",
      //@ts-ignore
      transformSource({ source }) {
        return {
          ...source,
          getItemInputValue({ item }) {
            return item.name;
          },
          onSelect({ item }) {
            setInstantSearchUiState({
              query: item.query,
              category: item.__autocomplete_qsCategory,
            });
          },
          getItems(params) {
            if (!params.state.query) {
              return [];
            }

            let array;
            let formattedQuery = params.state.query.toLowerCase();
            formattedQuery = formattedQuery.replace(
              /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]/g,
              ""
            );

            if (formattedQuery.length > 2 && formattedQuery.length <= 16) {
              array = fetchHit(formattedQuery);
            }

            return array ?? [];
          },
          templates: {
            ...source.templates,
            item({ item, components }) {
              return (
                <NameItem
                  hit={item}
                  components={components}
                  handleRegister={handleRegister}
                />
              );
            },
            header({ items, state }) {
              return (
                <Fragment>
                  <span className="aa-SourceHeaderTitle themeColor">
                    In Names
                  </span>
                  <span className="aa-SourceHeaderLine themeColor" />
                </Fragment>
              );
            },
          },
        };
      },
    });

    function highlight(props) {
      const { item, query } = props;
      return {
        ...item,
        _highlightResult: {
          nft_name: {
            value:
              query.length > 0
                ? item.nft_name.replace(
                    new RegExp(query, "gi"),
                    `<b>${query}</b>`
                  )
                : item.nft_name,
          },
        },
      };
    }

    const querySuggestionsInMarketplaceCategory = createQuerySuggestionsPlugin({
      searchClient,
      indexName: "market",
      //@ts-ignore
      transformSource({ source }) {
        return {
          ...source,
          getItemInputValue({ item }) {
            return item.nft_name;
          },

          sourceId: "market",
          onSelect({ item }) {
            if (item.collection_name.toString() === "FIND") {
              navigate(`/mp/${item.collection_name}/${item.nft_name}`);
            } else {
              navigate(`/mp/${item.collection_name}/${item.uuid}`);
            }
          },
          getItems(params) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: "market",
                  query: params.state.query,
                  params: {
                    clickAnalytics: true,
                    hitsPerPage: 24,
                    facetFilters: facetFilters,
                  },
                },
              ],
              //@ts-ignore
              transformResponse({ hits }) {
                let test = hits[0];
                let test1 = test.filter((item) => {
                  return (
                    item.nft_name
                      //@ts-ignore
                      .toLowerCase()
                      .includes(params.state.query.toLowerCase()) ||
                    (item.hasOwnProperty("seller_name")
                      ? item.seller_name
                          //@ts-ignore
                          .toLowerCase()
                          .includes(params.state.query.toLowerCase())
                      : false)
                  );
                });
                let test2 = test1.map((item) => {
                  return highlight({
                    item,
                    query: params.state.query.toLowerCase(),
                  });
                });
                hits[0] = test2;
                return hits;
              },
            });
          },
          templates: {
            ...source.templates,
            item({ item, html, components }) {
              return html`<div className="">
                <div className="aa-PanelSections">
                  <div className="aa-PanelSection--right">
                    ${(<MarketplaceItem hit={item} components={components} />)}
                  </div>
                </div>
              </div> `;
            },
            // noResults() {
            //   return "No products for this query.";
            // },
            header({ items }) {
              if (items.length === 0) {
                return <Fragment />;
              }

              return (
                <Fragment>
                  <span className="aa-SourceHeaderTitle themeColor">
                    In Marketplace
                  </span>
                  <span className="aa-SourceHeaderLine themeColor" />
                </Fragment>
              );
            },
          },
        };
      },
    });

    return [
      querySuggestionsInNamesCategory,
      querySuggestionsInMarketplaceCategory,
    ];
  }, []);

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return;
    }

    const autocompleteInstance = autocomplete({
      ...autocompleteProps,
      container: autocompleteContainer.current,
      initialState: { query },
      plugins,
      onReset() {
        setInstantSearchUiState({ query: "" });
      },
      onSubmit({ state }) {
        setInstantSearchUiState({ query: state.query });
      },
      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          debouncedSetInstantSearchUiState({
            query: state.query,
          });
        }
      },
      renderer: {
        createElement,
        Fragment,
        render,
      },
    });

    return () => autocompleteInstance.destroy();
  }, [plugins]);

  return <div className={className} ref={autocompleteContainer} />;
}
