import  { useEffect, useState } from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import { scripts } from "@findonflow/find-flow-contracts";

import { Script } from '../../../functions/script';
import EventsSwitch from '../../../functions/EventsSwitch';
import parseNameLink from '../../../functions/parseNameLink';

import FlowSticker from '../../forms/ftstickers/FlowSticker';
import FUSDSticker from '../../forms/ftstickers/FUSDSticker';

import "./mpdetailpage.css";


export default function MpDetailsOffersCard(props){
  const { activity, i } = props;

  const [updatedProfile, setUpdatedProfile] = useState(null);
  let profileAddress = activity.blockEventData.buyer ? activity.blockEventData.buyer : activity.blockEventData.bidder;
  let switchedHistory = EventsSwitch(activity);

  useEffect(() => {
    if (profileAddress) {
      // console.log(profileAddress)
      async function getProfile(addr) {
        const profileResponse = await Script(scripts.getProfile, {
          user: addr,
        });
        setUpdatedProfile(profileResponse);
      }
      try {
        getProfile(profileAddress);
      } catch (error) {
        console.log(error);
      }
    }
  }, [activity]);

  if (switchedHistory && activity) {
    // console.log(activity)
    return (
      <Row
        key={i}
        className="align-items-center my-1 mb-3"
      >
        <Col>
          <Card className=" latestOffersCard px-1 py-0">
            <Row className="align-items-center">
              <Col id="history-img-col" className={"flex-grow-0 px-0 me-3"}>
                <Image
                crossOrigin='anonymous'
                  className=""
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    marginLeft: "10px",
                  }}
                  src={
                    updatedProfile
                      ? updatedProfile.avatar
                      : activity.blockEventData.buyerAvatar
                      ? activity.blockEventData.buyerAvatar
                      : "/assets/img/store/fnamestore.png"
                  }
                />{" "}
              </Col>
              <Col id="history-desc-col" align="left" className={"px-0 me-2"}>
                <Row>{updatedProfile ? parseNameLink(updatedProfile) : null}</Row>
                <Row>
                  <span className="propertiesWrap">
                    {" "}
                    {new Date(activity.eventDate).toLocaleDateString()} -{" "}
                    {new Date(activity.eventDate).toLocaleTimeString()}{" "}
                  </span>
                </Row>
              </Col>
              <Col align="right" className={"flex-grow-0"}>
                <div className="flex-row">
                  <h3 className="mb-0">
                    {activity.blockEventData.amount * 1}{" "}
                  </h3>
                  &nbsp;
                  {switchedHistory.txFtType === "Flow" ? (
                    <FlowSticker noBg={'tertiary'} />
                  ) : (
                    <FUSDSticker noBg={'tertiary'} />
                  )}
                </div>
              </Col>
              <Col align="right" className={"w-auto flex-grow-0"}>
                <a
                  href={
                    process.env.REACT_APP_BLOCK_VIEWER_URL +
                    activity.flowTransactionId
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-solid fa-arrow-right linkIcon" />{" "}
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
}
