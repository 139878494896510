import { useEffect, useState } from 'react'
import { Card, Col, Row, Container, Image } from 'react-bootstrap'
import 'react-image-lightbox/style.css'
import { useHits } from 'react-instantsearch-hooks-web'
import { Script } from '../../functions/script'
import { UseThemeStatus } from '../../functions/themeMode'
import { scripts } from '@findonflow/find-flow-contracts'
import MakeBidNftMp from '../forms/buying/makebidnft/makebidnftmp'
import LatestBids from './latestbids/latestbids'
import MoreFromCollection from '../marketplace/morefromcollection/morefromcollection'
import useToastTxStatus from '../../functions/useToastTxStatus'
import { useStateChanged } from '../../functions/DisabledState'
import MpDetailMediaContent from './mpdetailpage/mpdetailmediacontent'
import MpDetailNameAndCol from './mpdetailpage/mpdetailnameandcol'
import MpDetailDescription from './mpdetailpage/mpdetaildescription'
import MpDetailOwnedBy from './mpdetailpage/mpdetailownedby'
import MpDetailSalePrice from './mpdetailpage/mpdetailsaleprice'
import MpDetailMakeOffer from './mpdetailpage/mpdetailmakeoffer'
import MpDetailRemoveListing from './mpdetailpage/mpdetailremovelisting'
import MpDetailBuyNow from './mpdetailpage/mpdetailbuynow'
import MpDetailAccordion from './mpdetailpage/mpdetailaccordion'
import useListingStatus from '../../functions/useListingStatus'
import axios from 'axios'
import './marketplace.css'
import useGetLatestBids from '../../functions/useGetLatestBids'
import { UseRefineQuery } from '../../functions/refineQuery'
import MpDetailBelowName from './MpDetailBelowName'
import MpDetailShareIcons from './mpdetailpage/mpdetailshareicons'
import MpDetailOffers from './mpdetailpage/mpdetailoffers'
import MpDetailRoyalties from './mpdetailpage/mpdetailroyalties'
import MpDetailPageDelisted from './mpdetailpagedelisted/mpdetailpagedelisted'
import { useLocation, useParams } from 'react-router'
import {
  getMarketplaceNFTLikes,
  hasUserLikedNFT,
  handleNFTLikeClicked,
  handleNFTFavClicked,
  hasUserFavNFT,
  handleNFTViewIncrement,
  getNFTViewCount,
} from '../../firebaseConfig'
import { HeartFill, Heart, StarFill, Star } from 'react-bootstrap-icons'
import Loading from '../loading/loading'

async function getNftListingDetails(user, project, id) {
  const listingArgs ={ user, project, id, views: [] }

  const allDetails = await Promise.all([
    // Ben: this will work for now
    Script(scripts.getNFTDetailsNFTCatalog,listingArgs),
    Script(scripts.getNFTDetailsNFTCatalogCommunity,listingArgs),
    Script(scripts.getProfile, {user})
  ])


  const [catalogDetails, communityDetails, profileDetails ] = allDetails

  const fullListing = {
    ...catalogDetails,
    ...communityDetails
  }

  return [fullListing, profileDetails]
}

export default function MarketplaceDetailPageHit({
  props,
  findUser,
  user,
  indexName,
  setIndexName,
}) {
  const { hits, results, sendEvent } = useHits(props)
  const [listingDetails, setListingDetails] = useState()
  const [profileDetails, setProfileDetails] = useState({})
  const [forSale, setForSale] = useState()
  const [forAuction, setForAuction] = useState()
  const [itemMediaType, setItemMediaType] = useState('')
  const [videoMediaLink, setVideoMediaLink] = useState('')
  const [isOffered, setIsOffered] = useState(false)
  const [ownItem, setOwnItem] = useState(false)
  const [userHighestBidder, setUserHighestBidder] = useState(false)
  const [userHasOffer, setUserHasOffer] = useState(false)
  const [auctionEnded, setAuctionEnded] = useState(false)
  const [isName, setIsName] = useState()
  const [fetchRun, setFetchRun] = useState(0)
  const [buyNowClicked, setBuyNowClicked] = useState(false)
  const themeStatus = UseThemeStatus()
  const toastStatus = useToastTxStatus()
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
  const [hasRun, setHasRun] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [hitCount, setHitCount] = useState(0)
  const { nft } = useParams()

  useEffect(() => {
    setHitCount((hitCount) => hitCount + 1)
  }, [hits])
  useEffect(() => {
    if (indexName !== 'delisted') {
      if (hitCount === 2) {
        if (indexName === 'market' && results.nbHits === 0) {
          setIndexName('sold')
        }
        if (indexName === 'sold' && results.nbHits === 0) {
          setIndexName('delisted')
        }
      }
      // } else {
      //   setIndexName('market')
      //   if (hitCount > 0){
      //   if (indexName === "market" && results.nbHits === 0) {
      //     setIndexName("sold");
      //   }
      //   if (indexName === "sold" && results.nbHits === 0) {
      //     setIndexName("delisted");
      //   }
      // }
    }
  }, [hitCount, nft])


  const query = UseRefineQuery()

  const listingStatus = useListingStatus(
    hits[0],
    listingDetails,
    profileDetails,
    isName,
    user
  )


  //get listing details and profile details
  useEffect(() => {
    if (hits && hits[0] && indexName !== 'delisted') {
      if (indexName === 'market') {
        //fetch listing and seller profile if nft
        if (hits[0]?.collection_alias !== 'FIND') {
          setIsName(false)
          try {
            if (!buyNowClicked) {
              getNftListingDetails(hits[0].seller, hits[0].nft_type, parseInt(hits[0].nft_id))
                .then(data=>{
                  const [listinDetails, profileDetails] = data;
                  setListingDetails(listinDetails)
                  setProfileDetails(profileDetails)
                })
            }
          } catch (error) {
            console.log(error)
          }
          // eslint-disable-next-line
        }
        //fetch listing and seller profile if a name
        else {
          setIsName(true)
          async function getNameListingDetails(name, addr) {
            const listingDetailsResponse = await Script(
              scripts.getNameDetails,
              {
                user: name,
              }
            )
            const profileDetailsResponse = await Script(scripts.getProfile, {
              user: addr,
            })
            setListingDetails(listingDetailsResponse)
            setProfileDetails(profileDetailsResponse)

            // //console.log(listingDetailsResponse);
          }
          try {
            if (!buyNowClicked) {
              getNameListingDetails(hits[0].nft_name, hits[0].seller)
            }
          } catch (error) {
            console.log(error)
          }
        }
        setHasRun(true)
      } else if (indexName === 'sold') {
        //fetch listing and seller profile if nft
        if (hits[0]?.collection_alias !== 'FIND') {
          setIsName(false)
          }
          try {
            if (!buyNowClicked) {
              getNftListingDetails(hits[0].buyer, hits[0].nft_type, parseInt(hits[0].nft_id))
                .then(data=>{
                  const [listinDetails, profileDetails] = data;
                  setListingDetails(listinDetails)
                  setProfileDetails(profileDetails)
                })
            }
          } catch (error) {
            console.log(error)
          }
          // eslint-disable-next-line
        }
        //fetch listing and seller profile if a name
        else {
          setIsName(true)
          async function getNameListingDetails(name, addr) {
            const listingDetailsResponse = await Script(
              scripts.getNameDetails,
              {
                user: name,
              }
            )
            const profileDetailsResponse = await Script(scripts.getProfile, {
              user: addr,
            })
            setListingDetails(listingDetailsResponse)
            setProfileDetails(profileDetailsResponse)

            // //console.log(listingDetailsResponse);
          }
          try {
            if (!buyNowClicked) {
              getNameListingDetails(hits[0].nft_name, hits[0].buyer)
            }
          } catch (error) {
            console.log(error)
          }
        }
        setHasRun(true)
      }
  }, [hits, useStateChanged()])

  let latestBids = useGetLatestBids(isName, listingStatus, listingDetails, hits)


  if (!listingDetails){ return <Loading/> }

  if (indexName === 'market' || indexName === 'sold') {
    return hits.map((hit, i) => (
      <Container key={i} className='detail-container'>
        <Card className='p-3 market-detail'>
          <Row
            id='mp-detail-content-row'
            className='pb-0 pb-sm-4 justify-content-center'
          >
            <Col align='center'>
              <MpDetailMediaContent
                hit={hit}
                isName={isName}
                listingStatus={listingStatus}
              />
            </Col>
          </Row>
          <Row>
            <Col xs='12' lg='6' id='mp-detail-left-col'>
              <MpDetailNameAndCol
                hits={hits}
                hit={hit}
                isName={isName}
                itemMediaType={itemMediaType}
                videoMediaLink={videoMediaLink}
                listingStatus={listingStatus}
              />
              <Row className='my-2 mb-4'>
                <MpDetailBelowName listingStatus={listingStatus} hit={hit} user={user} />
              </Row>

              {/* mobile top view */}
              <Col
                className='d-lg-none my-3'
                xs='12'
                lg='6'
                id='mp-detail-right-col'
              >
                {listingDetails && listingStatus.forAuction && (
                  <MakeBidNftMp
                    listingStatus={listingStatus}
                    listingDetails={
                      isName
                        ? listingDetails.leaseStatus
                        : listingDetails.findMarket.FindMarketAuctionEscrow
                    }
                    isName={isName}
                    findUser={findUser}
                    nftDetails={hit}
                    profileDetails={listingStatus.sellerProfile}
                    user={user}
                    ownItem={ownItem}
                    hit={hit}
                    userHighestBidder={userHighestBidder}
                    forAuction={forAuction}
                    auctionEnded={auctionEnded}
                    toastStatus={toastStatus}
                    themeStatus={themeStatus}
                    latestBid={latestBids[0]}
                  />
                )}

                {listingDetails && listingStatus.forSale && (
                  <MpDetailSalePrice
                    listingDetails={listingDetails}
                    isName={isName}
                    listingStatus={listingStatus}
                    user={user}
                    hit={hit}
                    toastStatus={toastStatus}
                    themeStatus={themeStatus}
                    buyNowClicked={buyNowClicked}
                    setBuyNowClicked={setBuyNowClicked}
                    query={query}
                    findUser={findUser}
                    hits={hits}
                  />
                )}

                {listingDetails &&
                  listingStatus.forAuction &&
                  listingStatus.auctionStarted &&
                  profileDetails && (
                    <Row className='px-0 pb-3 align-items-center'>
                      <LatestBids
                        user={user}
                        listingDetails={
                          isName
                            ? listingDetails.leaseStatus
                            : listingDetails.findMarket
                              .FindMarketAuctionEscrow
                        }
                        isName={isName}
                        forAuction={listingStatus.forAuction}
                        listingStatus={listingStatus}
                        eventsData={latestBids}
                      />
                    </Row>
                  )}

                {listingStatus && hit && (
                  <Row>
                    <MpDetailOffers
                      isName={isName}
                      nftId={isName ? hit.nft_name : hit.uuid}
                      listingStatus={listingStatus}
                      hit={hit}
                      themeStatus={themeStatus}
                      findUser={findUser}
                      listingDetails={listingDetails}
                      userHasOffer={userHasOffer}
                    />
                  </Row>
                )}

                {listingStatus && hit && !listingStatus.isName && (
                  <Row>
                    <Col>
                      <MpDetailRoyalties
                        isName={isName}
                        nftId={isName ? hit.nft_name : hit.uuid}
                        listingStatus={listingStatus}
                        hit={hit}
                        themeStatus={themeStatus}
                        findUser={findUser}
                        listingDetails={listingDetails}
                        userHasOffer={userHasOffer}
                      />
                    </Col>
                  </Row>
                )}
              </Col>

              <Row>
                <MpDetailAccordion
                  hit={hit}
                  isName={isName}
                  listingDetails={listingDetails}
                  user={user}
                  isOffered={isOffered}
                  listingStatus={listingStatus}
                />
              </Row>
              <Row className='pb-4 '>
                <Col className=''>
                  <MpDetailShareIcons />
                </Col>
              </Row>
            </Col>

            <Col
              className='d-none d-lg-block'
              xs='12'
              lg='6'
              id='mp-detail-right-col'
            >
              {listingDetails && listingStatus.forAuction && (
                <MakeBidNftMp
                  listingStatus={listingStatus}
                  listingDetails={
                    isName
                      ? listingDetails.leaseStatus
                      : listingDetails.findMarket.FindMarketAuctionEscrow
                  }
                  isName={isName}
                  findUser={findUser}
                  nftDetails={hit}
                  profileDetails={listingStatus.sellerProfile}
                  user={user}
                  ownItem={ownItem}
                  hit={hit}
                  userHighestBidder={userHighestBidder}
                  forAuction={forAuction}
                  auctionEnded={auctionEnded}
                  toastStatus={toastStatus}
                  themeStatus={themeStatus}
                  latestBid={latestBids[0]}
                />
              )}
              {listingDetails && listingStatus.forSale && (
                <MpDetailSalePrice
                  listingDetails={listingDetails}
                  isName={isName}
                  listingStatus={listingStatus}
                  user={user}
                  hit={hit}
                  toastStatus={toastStatus}
                  themeStatus={themeStatus}
                  buyNowClicked={buyNowClicked}
                  setBuyNowClicked={setBuyNowClicked}
                  query={query}
                  findUser={findUser}
                  hits={hits}
                />
              )}

              {listingDetails &&
                listingStatus.forAuction &&
                listingStatus.auctionStarted &&
                profileDetails && (
                  <Row className='px-0 pb-3 align-items-center'>
                    <Col>
                      <LatestBids
                        user={user}
                        listingDetails={
                          isName
                            ? listingDetails.leaseStatus
                            : listingDetails.findMarket.FindMarketAuctionEscrow
                        }
                        isName={isName}
                        forAuction={listingStatus.forAuction}
                        listingStatus={listingStatus}
                        eventsData={latestBids}
                      />
                    </Col>
                  </Row>
                )}

              {listingStatus && hit && (
                <Row>
                  <Col>
                    <MpDetailOffers
                      isName={isName}
                      nftId={isName ? hit.nft_name : hit.uuid}
                      listingStatus={listingStatus}
                      hit={hit}
                      themeStatus={themeStatus}
                      findUser={findUser}
                      listingDetails={listingDetails}
                      userHasOffer={userHasOffer}
                    />
                  </Col>
                </Row>
              )}

              {listingStatus && hit && !listingStatus.isName && (
                <Row>
                  <MpDetailRoyalties
                    isName={isName}
                    nftId={isName ? hit.nft_name : hit.uuid}
                    listingStatus={listingStatus}
                    hit={hit}
                    themeStatus={themeStatus}
                    findUser={findUser}
                    listingDetails={listingDetails}
                    userHasOffer={userHasOffer}
                  />
                </Row>
              )}

            </Col>
            <Row className=' pt-5 align-items-center'>
              <h4>More from this collection</h4>
            </Row>
            <Row className='justify-content-center align-items-center'>
              <MoreFromCollection
                isName={isName}
                colName={hit.nft_alias}
                detail={true}
              />
            </Row>
          </Row>
        </Card>
      </Container>

      // <Container key={i} className='detail-container'>
      //   <Card className="p-3 market-detail">
      //     <Row className="mb-3">
      // <MpDetailMediaContent
      //   hits={hits}
      //   itemMediaType={itemMediaType}
      //   videoMediaLink={videoMediaLink}
      //   isName={isName}
      //   listingStatus={listingStatus}
      // />
      //       <Col>
      //         <Row className="h-100 align-content-start">
      // <MpDetailNameAndCol
      //   hits={hits}
      //   hit={hit}
      //   isName={isName}
      //   itemMediaType={itemMediaType}
      //   videoMediaLink={videoMediaLink}
      // />

      // {listingDetails && (
      //   <MpDetailDescription
      //     isName={isName}
      //     listingDetails={listingDetails}
      //     listingStatus={listingStatus}
      //   />
      // )}

      // {listingStatus.sellerProfile && (
      //   <MpDetailOwnedBy profileDetails={listingStatus.sellerProfile} />
      // )}

      // {listingDetails && listingStatus.forAuction && (
      //   <MakeBidNftMp
      //     listingStatus={listingStatus}
      //     listingDetails={
      //       isName
      //         ? listingDetails.leaseStatus
      //         : listingDetails.findMarket.FindMarketAuctionEscrow
      //     }
      //     isName={isName}
      //     findUser={findUser}
      //     nftDetails={hit}
      //     profileDetails={listingStatus.sellerProfile}
      //     user={user}
      //     ownItem={ownItem}
      //     hit={hit}
      //     userHighestBidder={userHighestBidder}
      //     forAuction={forAuction}
      //     auctionEnded={auctionEnded}
      //     toastStatus={toastStatus}
      //     themeStatus={themeStatus}
      //     latestBid={latestBids[0]}
      //   />
      // )}

      // {listingDetails && listingStatus.forSale && (
      //   <MpDetailSalePrice
      //     listingDetails={listingDetails}
      //     isName={isName}
      //     listingStatus={listingStatus}
      //   />
      // )}

      //       <Row className="justify-content-end my-2">
      // <Col align="center" xs="12" md="6" className="pb-xs-2">
      //   {listingDetails && (!user || !listingStatus.ownItem) ? (
      //     <MpDetailMakeOffer
      //       hit={hit}
      //       themeStatus={themeStatus}
      //       findUser={findUser}
      //       profileDetails={listingStatus.sellerProfile}
      //       listingDetails={listingDetails}
      //       userHasOffer={userHasOffer}
      //       isName={isName}
      //     />
      //   ) : (
      //     <MpDetailRemoveListing
      //       hits={hits}
      //       isName={isName}
      //       forSale={forSale}
      //       forAuction={forAuction}
      //       listingDetails={listingDetails}
      //       themeStatus={themeStatus}
      //       listingStatus={listingStatus}
      //     />
      //   )}
      // </Col>

      // {listingStatus.forSale && !listingStatus.ownItem && (
      //   <MpDetailBuyNow
      //     hit={hit}
      //     isName={isName}
      //     listingDetails={listingDetails}
      //     toastStatus={toastStatus}
      //     themeStatus={themeStatus}
      //     buyNowClicked={buyNowClicked}
      //     setBuyNowClicked={setBuyNowClicked}
      //     query={query}
      //     findUser={findUser}
      //   />
      // )}
      //       </Row>
      //     </Row>
      //   </Col>
      // </Row>

      // {listingDetails &&
      //   listingStatus.forAuction &&
      //   listingStatus.auctionStarted &&
      //   profileDetails && (
      //     <Row className="px-3 pb-3 align-items-center">
      //       <Col>
      //         <LatestBids
      //           user={user}
      //           listingDetails={
      //             isName
      //               ? listingDetails.leaseStatus
      //               : listingDetails.findMarket.FindMarketAuctionEscrow
      //           }
      //           isName={isName}
      //           forAuction={listingStatus.forAuction}
      //           listingStatus={listingStatus}
      //           eventsData={latestBids}
      //         />
      //       </Col>
      //     </Row>
      //   )}

      // <MpDetailAccordion
      //   hit={hit}
      //   isName={isName}
      //   listingDetails={listingDetails}
      //   user={user}
      //   isOffered={isOffered}
      //   listingStatus={listingStatus}
      // />

      // <Row className="px-4 pb-3 align-items-center">
      //   <h4>More from this collection</h4>
      // </Row>
      // <Row className="justify-content-center align-items-center px-2">
      //   <MoreFromCollection isName={isName} nftDetails={hit} />
      // </Row>
      //   </Card>
      // </Container>
    ))
  } else {
    return <MpDetailPageDelisted />
  }
}
